import {Realm, Region, RegionToRealmMap} from 'oci-console-regions';

const ENDPOINT_VERSION_1 = 'v1';
const ENDPOINT_VERSION_20160918 = '20160918';
const SERVICE_LOGIN = 'login';
const SERVICE_CONSOLE = 'ocistatus';
const SERVICE_IDENTITY = 'identity';
const SERVICE_AUTHENTICATION = 'auth';

export const mustNotEmptyNullOrUndefined = (
  name: string,
  value: any,
  errorMessage?: string,
) => {
  if (!value) {
    throw new Error(
      errorMessage || `${name} must not be empty, null or undefined.`,
    );
  }
};

export const ConsoleDefaultRegionMapping = new Map<Realm, Region>([
  [Realm.OC1, Region.PHX],
  [Realm.OC2, Region.LFI],
  [Realm.OC3, Region.RIC],
  [Realm.OC4, Region.LTN],
  [Realm.OC5, Region.TIW],
  [Realm.OC6, Region.FTW],
  [Realm.OC7, Region.BWI],
  [Realm.OC8, Region.NJA],
  [Realm.OC9, Region.MCT],
  [Realm.OC10, Region.WGA],
  [Realm.OC11, Region.HWY],
  [Realm.OC12, Region.PIT],
  [Realm.OC14, Region.BGY]
]);

const realmTopLevels = new Map<Realm, string>([
  [Realm.R1, 'oracleiaas.com'],
  [Realm.OC1, 'oraclecloud.com'],
  [Realm.OC2, 'oraclegovcloud.com'],
  [Realm.OC3, 'oraclegovcloud.com'],
  [Realm.OC4, 'oraclegovcloud.uk'],
  [Realm.OC5, 'oraclecloud5.com'],
  [Realm.OC6, 'oraclecloud.ic.gov'],
  [Realm.OC7, 'oc.ic.gov'],
  [Realm.OC8, 'oraclecloud8.com'],
  [Realm.OC9, 'oraclecloud9.com'],
  [Realm.OC10, 'oraclecloud10.com'],
  [Realm.OC11, 'oraclecloud11.com'],
  [Realm.OC12, 'oraclecloud12.com'],
  [Realm.OC14, 'oraclecloud14.com']
]);

export const getTopLevel = (region: string): string => {
  const regionEnum = region as Region;
  const realm = RegionToRealmMap[regionEnum];
  return realmTopLevels.get(realm) || '';
};

export const getSanitizedRegion = (regionName: string): string => {
  return regionName.toLowerCase() === 'us-seattle-1' ? 'r1' : regionName;
};

export const buildServiceUrl = (
  serviceName: string,
  region: string,
  secondLevelDomain: string,
  version?: string,
  pattern?: string,
): string => {
  mustNotEmptyNullOrUndefined('serviceName', serviceName);
  mustNotEmptyNullOrUndefined('secondLevelDomain', secondLevelDomain);

  // take care of r1 special case where it doesn't use region name as sub-domain
  let serviceUrl =
    pattern || 'https://{serviceName}.{region}.{secondLevelDomain}/{version}';

  serviceUrl = serviceUrl
    .replace(/{serviceName}/gi, serviceName)
    .replace(/{secondLevelDomain}/gi, secondLevelDomain)
    .replace(/{version}/gi, version || '')
    .replace(/\/$/, ''); // remove trailing "/"

  return region
    ? serviceUrl.replace(/{region}/gi, getSanitizedRegion(region))
    : serviceUrl.replace(/{region}\./gi, '');
};

/**
 * Returns the Login URL for the given regionName
 * @param regionName
 * @returns string
 */
export const buildLoginUrl = (regionName: string): string => {
  /**
   * Add a special case to point SOUP to beta unstable for r1-staging.
   * This is done to enable better testing in unstable regions
   */

  return buildServiceUrl(
    SERVICE_LOGIN,
    regionName,
    getTopLevel(regionName),
    ENDPOINT_VERSION_1,
  );
};

/**
 * Returns the Console URL for the given regionName
 * @param regionName
 * @returns string
 */
export const buildConsoleUrl = (regionName: string): string => {
  return buildServiceUrl(SERVICE_CONSOLE, regionName, getTopLevel(regionName));
};

/**
 * Returns the Identity URL for the given regionName
 * @param regionName
 * @returns string
 */
export const buildIdentityUrl = (regionName: string): string => {
  return buildServiceUrl(
    SERVICE_IDENTITY,
    regionName,
    getTopLevel(regionName),
    ENDPOINT_VERSION_20160918,
  );
};

/**
 * Returns the Authorization URL for the given regionName
 * @param regionName
 * @returns string
 */
export const buildAuthorizationUrl = (regionName: string): string => {
  return buildServiceUrl(
    SERVICE_AUTHENTICATION,
    regionName,
    getTopLevel(regionName),
    ENDPOINT_VERSION_1,
  );
};
